





















































import {
defineComponent, onMounted, ref,
useRoute,
} from '@nuxtjs/composition-api';
import { SfButton, SfIcon, SfSearchBar } from '@storefront-ui/vue';
import { debounce } from 'lodash-es';
import { searchIcon } from '~/cms/helpers/customIcons';
import { FontColor } from '~/components/AppHeader.vue';
import SvgImage from '~/components/General/SvgImage.vue';
import { clickOutside } from '~/components/directives/click-outside/click-outside-directive';
import { searchTermQueryParameter } from '~/helpers/routes';

export default defineComponent({
  name: 'SearchBar',
  components: {
    SfSearchBar,
    SfButton,
    SvgImage,
    SfIcon,
  },
  directives: { clickOutside },
  props: {
    isSearchOpen: {
      type: Boolean,
      default: false,
    },
    itemsPerPage: {
      type: Number,
      default: 12,
    },
    minTermLen: {
      type: Number,
      default: 2,
    },
    fontColor: {
      type: String,
      default: FontColor.White,
    },
  },
  emits: ['set-is-open', 'set-search'],
  setup(props, { emit }) {
    const term = ref('');
    const route = useRoute();

    const showSearch = () => {
      if (!props.isSearchOpen) {
        emit('set-is-open', true);
      }
    };

    const hideSearch = () => {
      emit('set-is-open', false);
    };

    const toggleSearch = () => {
      if (props.isSearchOpen) {
        hideSearch();
      } else {
        showSearch();
      }
    };

    const closeSearch = () => {
      hideSearch();
    };

    const clearSearch = () => {
      emit('clear-search');
      term.value = '';
    };

    const rawHandleSearch = (searchTerm: string) => {
      term.value = searchTerm;
      if (term.value.length < props.minTermLen) return;

      emit('set-search', term.value);
    };

    const debouncedHandleSearch = debounce(rawHandleSearch, 1000);

    const handleKeyupEnter = (searchTerm: string) => {
      // cancel debounce timeout started by typing into the searchbar - this is to avoid making two network requests instead of one
      debouncedHandleSearch.cancel();
      rawHandleSearch(searchTerm);
    };

    onMounted(() => {
      if (route.value.query.term) {
        // eslint-disable-next-line prefer-destructuring
        term.value = String(route.value.query[searchTermQueryParameter]);
        showSearch();
      }
    });

    return {
      closeSearch,
      showSearch,
      hideSearch,
      toggleSearch,
      rawHandleSearch,
      debouncedHandleSearch,
      handleKeyupEnter,
      term,
      FontColor,
      searchIcon,
      clearSearch,
    };
  },
  methods: {
    updateTerm(term: string) {
      this.term = term;
    },
  },
});

